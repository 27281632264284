<template>
  <v-app style="background-color: black">
    <img src="/logo.png" alt="" style="display: block; margin: auto auto 10px auto" ><br>
    <img src="/load.gif"  width="50" style="display: block; margin: 20px auto auto auto">
  </v-app>

</template>

<script>
import axios from 'axios';
export default {
  name: "LoadPaeg",
	async beforeCreate(){
    try {
      let response = await axios.get('https://api.everinvestor.com.br/api/links/' + this.$route.params.route);
      if (response.data.hub) await this.$router.replace('/hub/' + this.$route.params.route);
      else {
        let target = response.data.link;
        window.location.href = target;
      }
    } catch {
      await this.$router.replace('/')
    }
	}
}
</script>

<style>

html {
  overflow-y: auto;
  background-color: black !important;
}

</style>
