<template>
  <v-row justify="center">
    <account-block v-for="account of accounts" :account="account" :key="account.id"/>
  </v-row>
</template>

<script>
import AccountBlock from "./accountBlock";
export default {
  components: {AccountBlock},
  props: {
    accounts: Array,
  },
  name: "accountsBlockList"
}
</script>

<style scoped>

</style>