import Vue from "vue";
import VueRouter from "vue-router";
import App from "./App.vue";
import vuetify from "./plugins/vuetify";
import Home from "./components/Home";
import Hub from "./components/Hub";
import LoadPaeg from "./LoadPaeg";
import Capital from "./Capital";
import Alunos from "./Alunos"

Vue.config.productionTip = false;
Vue.use(VueRouter);

const router = new VueRouter({
  mode: "history",
  routes: [
    {
      path: "/",
      component: Home
    },
    {
      path: "/capital",
      component: Capital
    },
    {
      path: "/alunos",
      component: Alunos
    },
    {
      path: "/:route",
      component: LoadPaeg
    },
    {
      path: "/hub/:route",
      component: Hub
    },
  ]
});

new Vue({
  vuetify,
  router,
  render: h => h(App)
}).$mount("#app");
