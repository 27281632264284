<template>
  <v-app style="background-color: black">
    <v-main>
      <router-view></router-view>
    </v-main>
    <v-footer class="footer">
      <div class="icon-array">
        <router-link to="facebook" target="_blank"><v-icon class="white--text access-icon">mdi-facebook</v-icon></router-link>
        <router-link to="youtube" target="_blank"><v-icon class="white--text access-icon">mdi-youtube</v-icon></router-link>
        <router-link to="instagram" target="_blank"><v-icon class="white--text access-icon">mdi-instagram</v-icon></router-link>
      </div>
      <div class="copycopy">
        &copy; {{ new Date().getFullYear() }} <strong>Ever Investor</strong>, all
        rights reserved.
      </div>
      <v-row class="mb-10">
          <v-col align="right">
              <p class="city">São Paulo</p>
              <p class="address">Brazilian Financial Center</p>
              <p class="address">Av. Paulista 1374, 12° andar</p>
              <p class="address">São Paulo - SP</p>
          </v-col>
          <v-divider vertical inset color="gray"></v-divider>
          <v-col>
              <p class="city">Belém</p>
              <p class="address">Quadra Corporate</p>
              <p class="address">Av. Visconde de Souza Franco 05, 20° andar</p>
              <p class="address">Belém - PA</p>
          </v-col>
      </v-row>
    </v-footer>
  </v-app>
</template>

<script>

export default {
  name: 'App',
  data: () => ({
    //
  }),
};
</script>
<style>

/* width */
::-webkit-scrollbar {
  width: 7px;
}
::-webkit-scrollbar-track {
  background: black;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #1b1b1b;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #303030;
}

.city {
  font-size:14pt
}

.address {
  font-size: 10pt;
  margin-bottom: 0px !important;
  color: gray;
}

html {
  overflow-y: auto;
  background-color: black;
}


body{
  background-color: black ;
}


.footer{
  background-color: black !important;
  color: white !important;
}

.icon-array{
  display: block;
  margin-top: 60px;
  width: 100vw;
  text-align: center;
}

.icon-array a{
  text-decoration: none;
}

.copycopy{
  display: block;
  margin-top: 25px;
  margin-bottom: 50px;
  width: 100vw;
  text-align: center;
}

.access-icon{
  margin: 0 10px;
}


</style>
