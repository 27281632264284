<template>
  <div>
    <v-row class="main-display">
      <v-col>
        <div class="main-statement">
            <img style="margin-top: 20px;vertical-align: middle"
                 src="/logo.png"
                 alt=""
            />
        </div>

        <v-row class="main-acts" justify="center">
          <v-col cols="12" md="4" lg="3" xl="2" align="center">
            <v-btn class="main-act-button" to="acompanhamento-login" target="_blank" text>Plataforma de acompanhamento</v-btn>
          </v-col>
          <v-col cols="12" md="4" lg="3" xl="2" align="center">
            <v-btn class="main-act-button" to="whatsapp" target="_blank" text>Quero começar agora</v-btn>
          </v-col>
        </v-row>
      </v-col>
    </v-row>
    <div style="background-color: black">
      <div class="left-side-picture"></div>
      <div class="home-text">
        <v-container fluid style="height: 100%; padding-inline: 40px">
          <v-row no-gutters align-content="center" style="height: 100%">
            <v-col class="text-subtitle-1 font-weight-regular text-justify">
              <p>
                Evoluímos nossa empresa, nossa marca e nossa plataforma para que
                nossos clientes evoluam muito mais.
              </p>

              <p>
                Após uma década compartilhando conhecimento, encarando tantos
                desafios, mudanças e revoluções, nos fortalecemos e estamos
                preparados para continuar em busca de conquistas maiores junto com
                você.
              </p>
            </v-col>
            <v-col cols="12" class="mt-2">
              <v-btn
                  to="whatsapp"
                  block
                  text
                  class="sac-button"
                  target="_blank"
              >Fale com a nossa equipe</v-btn
              >
            </v-col>
          </v-row>
        </v-container>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "Home",
    methods: {
      goToXb() {
          const link = 'https://portal.xbprime.com/pt/links/go/676';
          window.open(link, '_blank');
      }
    }
};
</script>

<style scoped>
.main-display {
  height: 103vh;
  background-image: url("/bg.jpg");
  background-position: right center;
  background-size: cover;
}

.main-statement {
  margin-top: 30vh;
  text-align: right;
  padding-right: 12%;
  vertical-align: middle;
}

.sub-statement {
    display: block;
    margin-top: 40px;
    margin-left: auto;
    margin-right: 50px;
    cursor: pointer;
}

.home-text {
  width: 50%;
  float: left;
  height: 610px;
  margin-top: -610px;
  background-color: rgba(255, 255, 255, 0.9);
}

.left-side-picture {
  vertical-align: middle;
  background-image: url("/foto.jpg");
  background-size: contain;
  display: block;
  height: 610px;
  width: 100%;
  float: right;
  margin-top: 10px;
  background-position: center right;
}

.main-act-button {
  border: 1px solid white;
  color: white !important;
  border-radius: 10px !important;
  transition: 0.5s !important;
}

.main-act-button:hover {
  background-color: white;
  color: black !important;
}

.sac-button {
  border: 1px solid black;
  color: black !important;
  border-radius: 10px !important;
  transition: 0.5s !important;
}

.sac-button:hover {
  background-color: black;
  color: white !important;
}

.main-acts {
  margin-top: 30vh !important;
}

@media screen and (max-width: 1299px) {
  .main-display {
    background-position: -150px center;
  }

  .main-statement {
    margin-top: 45vh;
    margin-bottom: 12vh;
    text-align: center;
    padding-right: 0;
    vertical-align: middle;
  }

  .sub-statement {
      display: block;
      margin-top: 40px;
      margin-left: auto;
      margin-right: auto;
      cursor: pointer;
  }

  .main-acts {
    margin-top: 25px !important;
  }

  .left-side-picture {
    display: block;
    height: 400px;
    width: 100%;
    background-size: cover;
    float: none;
  }

  .home-text {
    padding: 40px 0;
    width: 100%;
    float: none;
    height: auto;
    vertical-align: middle;
    margin-top: 0;
    background-color: rgba(255, 255, 255, 0.9);
  }
}
</style>
