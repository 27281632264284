<template>
  <v-col :key="account.id" cols="12">
    <a :href="`https://www.myfxbook.com/members/EverInvestor/ever-investor/${account.id}`" target="_blank">
      <v-row v-ripple class="px-2 py-1" dense style="min-height: 80px;cursor: pointer; box-shadow: 0 0 2px grey; border-radius: 5px; background-color: #f8f8f8" >
        <v-col class="text-h6" align-self="center">
          {{ account.name }}
        </v-col>
        <v-col :class="account.gain > 0 ? 'green--text' : 'red--text'" class="text-h6 font-weight-black hidden-xs-only" align="center" cols="auto" sm="3" md="3" lg="3" align-self="center">
          {{ parseFloat(account.gain).toFixed(2) }}%
        </v-col>
        <v-col :class="account.monthly > 0 ? 'green--text' : 'red--text'" class="text-h6 font-weight-medium hidden-xs-only" align="center" sm="3" md="3" lg="3" align-self="center">
          {{ account.monthly }}%
        </v-col>
        <v-col class="hidden-sm-and-up" cols="12" align-self="center">
          <span class="font-weight-medium">Ganho Total:</span> <span :class="account.gain > 0 ? 'green--text' : 'red--text'" class="text-h6 font-weight-black">{{ parseFloat(account.gain).toFixed(2) }}%</span>
        </v-col>
        <v-col class="hidden-sm-and-up" cols="12" align-self="center">
          <span>Média Mensal:</span> <span :class="account.monthly > 0 ? 'green--text' : 'red--text'" class="text-h6 font-weight-medium">{{ account.monthly }}%</span>
        </v-col>
        <v-col class="grey--text hidden-sm-and-up" cols="12" sm="3" md="3" lg="3" align-self="center">
          Conta criada em {{ formatDate(account.creationDate) }}
        </v-col>
        <v-col class="hidden-xs-only" cols="12" sm="3" md="3" lg="3" align="end" align-self="center">
          {{ formatDate(account.firstTradeDate) }}
        </v-col>
      </v-row>
    </a>
  </v-col>
</template>

<script>
import {DateTime} from "luxon";

export default {
  props: {
    account: Object,
  },
  name: "accountBlock",
  methods: {
    formatDate(date) {
      let dateOnly = date.split(' ')[0]
      let dateTime = DateTime.fromFormat(dateOnly, "MM/dd/yyyy")
      return dateTime.setLocale('pt-BR').toFormat("MMMM 'de' y");
    }
  },
}
</script>

<style scoped>
  a {
    text-decoration: none !important;
    color: inherit !important;
  }
</style>