<template>
  <v-row no-gutters v-if="!$vuetify.breakpoint.xsOnly" justify="center">
    <v-col align-self="center">
      Nome
    </v-col>
    <v-col align="center" cols="auto" sm="3" md="3" lg="3" align-self="center">
      Ganho Total
    </v-col>
    <v-col align="center" cols="auto" sm="3" md="3" lg="3" align-self="center">
      Média Mensal
    </v-col>
    <v-col cols="12" sm="3" md="3" lg="3" align="end" align-self="center">
      Data de criação
    </v-col>
    <v-col class="mt-3" cols="12">
      <v-divider/>
    </v-col>
  </v-row>
</template>

<script>
export default {
  name: "accountListHeader"
}
</script>

<style scoped>

</style>