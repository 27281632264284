<template>
  <v-row class="py-8" justify="center" style="background-color: #ffffff">
    <v-col cols="11" style="max-width: 1050px">
      <account-list-header/>
      <accounts-block-list :accounts="accounts"/>
    </v-col>
  </v-row>
</template>

<script>
import AccountListHeader from "../capital/accountListHeader";
import AccountsBlockList from "./accountsBlockList";
export default {
  name: "accounts",
  props: {
    accounts: Array,
  },
  components: {AccountsBlockList, AccountListHeader}
}
</script>

<style scoped>

</style>