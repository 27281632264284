<template>
  <v-container fluid>
    <v-row class="py-12 banner" justify="center">
      <v-col cols="auto" align-self="center">
        <v-img
            src="/alunos-logo.png"
            alt=""
        />
      </v-col>
    </v-row>
    <accounts :accounts="accounts"/>
  </v-container>
</template>

<script>
import axios from "axios";
import Accounts from "./components/alunos/accounts";

export default {
  name: "Capital",
  components: {Accounts},
  data: () => ({
    accounts: [],
  }),
  async mounted() {
    this.accounts = (await axios.get('https://accounts.fx.everinvestor.com.br/')).data
  }
}
</script>

<style scoped>
a {
  text-decoration: none !important;
  color: inherit !important;
}
.banner {
  background-image: url('/capital-banner.jpg');
  background-size: cover;
}
</style>
