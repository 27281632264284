<template>
  <v-app style="background-color: black">
    <v-container fluid style="margin: auto auto auto auto">
      <v-row class="main-display" align="center" justify="center" dense>
        <v-col cols="auto">
          <v-img src="/logo.png" max-width="340" alt="" />
        </v-col>
        <v-col cols="11" class="py-8">
          <v-divider dark/>
        </v-col>
        <v-col cols="auto">
          <v-btn
              rounded
              style="border-radius: 30px"
              @click="redirectTo"
              :loading="loading"
              class="py-8 white--text"
              color="green"
          >
            <v-icon left large class="px-4">  mdi-arrow-right-drop-circle-outline </v-icon>
            Continuar
          </v-btn>
        </v-col>
      </v-row>
    </v-container>
  </v-app>
</template>
<script>

import axios from "axios";

export default {
  name: "Hub",
  data: () => ({
    link: '',
    loading: false,
  }),
  async beforeCreate(){
    try {
      let response = await axios.get('https://api.everinvestor.com.br/api/links/' + this.$route.params.route);
      this.link = response.data.link;
    } catch {
      await this.$router.replace('/')
    }
  },
  methods: {
    redirectTo() {
      this.loading = true;
      window.location.replace(this.link);
    }
  }
};
</script>

<style scoped>
html {
  overflow-y: auto;
  background-color: black !important;
}
</style>
